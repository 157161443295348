.sr {

    &_preview {
        position: relative;

        min-height: 100vh;
        background: linear-gradient(111.78deg, #000B32 6.74%, #000517 91.78%);

        display: flex;
        align-items: center;
        color: #FFFFFF;

        &__info {
            position: relative;
            z-index: 1;

            width: 50%;

            display: flex;
            //gap: 24px;
            flex-direction: column;
            align-items: flex-start;

            &-name {
                margin-bottom: 24px;
                background: rgba(255, 255, 255, 0.1);
                border-radius: 5px;
                padding: 8px 16px;

                font-weight: 600;
                font-size: 12px;
                line-height: 20px;

                letter-spacing: 0.1em;
                text-transform: uppercase;
            }

            &-title {
                @include page-heading-h1($white, true);
                //font-weight: 700;
                //font-size: 48px;
                //line-height: 60px;
            }

            &-paragraph {
                @include page-heading-h1-description($white, true);
                //font-weight: 400;
                //font-size: 16px;
                //line-height: 26px;
                //color: #E0E4EC;

                //& + & {
                //    padding-top: 10px;
                //}
            }
        }

        &__bg {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: 100%;

            background-image: url("/images/main/your-rates/bg-md.jpg");
            background-position: bottom;
            background-size: cover;
            background-repeat: no-repeat;
        }

        // 992
        @include media-breakpoint-down(md) {
            &__info {
                width: 65%;
                padding: 186px 0 420px 0;
            }

            &__bg {
                background-image: url("/images/main/your-rates/bg-sm.jpg");
            }
        }

        // 576
        @include media-breakpoint-down(xs) {
            &__info {
                width: 100%;
                padding: 136px 0 120vw 0;

                //&-title {
                //    font-weight: 600;
                //    font-size: 24px;
                //    line-height: 34px;
                //}

                &-paragraph {

                    & + & {
                        padding-top: 0;
                    }
                }

                &-btn {
                    width: 100% !important;
                }
            }

            &__bg {
                background-image: url("/images/main/your-rates/bg-xs.jpg");
            }
        }

        @media (max-height: 620px) {
            padding: 80px 0 40px 0;
        }
    }

    &_information {
        padding: 145px 0 216px 0;

        &__rate {
            display: grid;
            grid-template-columns: 1.2fr 1fr;
            gap: 70px;

            margin-bottom: 150px;

            &-title {
                margin-bottom: 32px;
            }
        }

        &__lead {
            display: grid;
            grid-template-columns: 1fr 1.2fr;
            gap: 60px;

            &-title {
                margin-bottom: 40px;
            }
        }

        &__info {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &__image {

            img {
                width: 100%;
            }
        }

        &__title {
            font-weight: 600;
            font-size: 36px;
            line-height: 48px;

            p {
                font-size: 20px;
                line-height: 30px;
                text-transform: uppercase;
            }
        }

        &__paragraph {
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;

            & + & {
                padding-top: 15px;
            }
        }

        // 1200
        @include media-breakpoint-down(lg) {
            padding: 115px 0 170px 0;

            &__rate {
                gap: 40px;
                margin-bottom: 125px;
            }

            &__lead {
                gap: 40px;
            }
        }

        // 992
        @include media-breakpoint-down(md) {
            &__rate {
                grid-template-columns: 1fr;
            }

            &__lead {
                display: flex;
                flex-direction: column-reverse;
            }
        }

        // 768
        @include media-breakpoint-down(sm) {
            padding: 95px 0 100px 0;

            &__title {
                font-weight: 700;
                font-size: 22px;
                line-height: 32px;

                p {
                    font-weight: 600;
                    font-size: 18px;
                    line-height: 28px;
                }
            }
        }
    }

    &_list {
        padding: 160px 0;
        background: #030A27;

        &__ul {
            margin-top: 0;
        }

        .freight-forwarders__list-title {
            color: #FFFFFF;
        }

        .freight-forwarders__list-text {
            color: #6E7E92;
        }

        // 992
        @include media-breakpoint-down(md) {
            padding: 140px 0;
        }

        // 768
        @include media-breakpoint-down(sm) {
            padding: 100px 0;
        }
    }
}
