@import '../../ui/arrow';

.home-slider {
  .swiper-pagination {
    z-index: 2;
    background: transparent;
    position: absolute;
    bottom: 5px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
  }
  &__slide {
    background: $dark-blue;
    height: 600px;
    display: flex;
    align-items: center;
  }

  &__slide-content-box {
    display: flex;
  }

  &__slide-description {
    max-width: 40%;
  }

  &__slide-logo {
    margin-bottom: 30px;
    max-width: 122px;
  }

  &__slide-title {
    @include text(36px, 600, none, unset, $white);
    line-height: 48px;
    margin-bottom: 16px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }

    @media (max-width: 576px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__slide-text {
    @include text(16px, 500, none, unset, #e0e4ec);
    line-height: 26px;
    margin-bottom: 24px;
  }

  &__slide-link {
    color: $blue-middle-hover;

    .c-arrow {
      stroke: $blue-middle-hover;
    }

    &:hover {
      .c-arrow {
        @include addArrowAnimation;
      }
    }
  }

  &__slide-list {
    display: flex;
    justify-content: space-between;
  }

  &__slide-list-item {
    flex: 0 0 23%;
    color: $white;

    svg {
      margin-bottom: 17px;
    }
  }

  &__slide-list-item-title {
    @include text(16px, 600, none, unset, $white);
    line-height: 26px;
    margin-bottom: 8px;
  }

  &__slide-list-item-text {
    @include text(14px, 500, none, unset, #c9cfdb);
    line-height: 22px;
  }

  .timeline-icon {
    position: relative;
    font-size: 45px;
    margin-left: 5px;
    color: $grey;

    &:hover {
      cursor: pointer;
    }

    &-active {
      color: $white;
    }
  }

  .slide1 {
    background-image: url('/images/pages/Home/home-slider__slide1-bg.webp');
    background-size: cover;

    .home-slider {
      &__slide {
        background: $dark-blue;
      }

      &__slide-content-box {
        flex-direction: column;

        @media (max-width: 768px) {
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
        }

        @media (max-width: 576px) {
          flex-direction: column;
          align-items: unset;
          justify-content: unset;
        }
      }

      &__slide-list {
        @media (max-width: 768px) {
          flex-direction: column;
          margin-left: 20px;
        }

        @media (max-width: 576px) {
          margin-left: 0;
        }
      }

      &__slide-list-item-text {
        @media (max-width: 768px) {
          display: none;
        }
      }

      &__slide-list-item {
        @media (max-width: 768px) {
          display: flex;
          align-items: center;
          margin-bottom: 16px;

          svg {
            margin-right: 10px;
            margin-bottom: 0;
          }
        }
      }

      &__slide-list-item-title {
        @media (max-width: 768px) {
          margin-bottom: 0;
        }
      }

      &__slide-description {
        margin-bottom: 80px;

        @media (max-width: 768px) {
          max-width: 52%;
        }

        @media (max-width: 576px) {
          max-width: 100%;
        }
      }
    }
  }

  .slide2 {
    .home-slider {
      &__slide-content-box {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &__slide-description {
        flex: 0 0 40%;

        @media (max-width: 630px) {
          flex: 0 0 90%;
          max-width: 100%;
        }
      }

      &__slide-image-box {
        flex: 0 0 55%;

        @media (max-width: 630px) {
          display: none;
        }
      }

      &__slide-image {
        max-width: 636px;

        @media (max-width: 768px) {
          max-width: 430px;
          margin-right: -100px;
        }
      }

      &__slide-title-box {
        position: relative;

        h1 {
          margin-bottom: 0;
        }

        svg {
          position: absolute;
          top: 0;
          left: 0;
          z-index: 0;

          @media (max-width: 768px) {
            max-width: 212px;
            top: -8px;
          }

          @media (max-width: 576px) {
            max-width: 147px;
            top: -12px;
          }
        }
      }

      &__slide-title {
        position: relative;
        z-index: 2;
      }
    }
  }

  .slide3 {
    position: relative;
    &__bg {
      position: absolute;
      width: 100%;
      height: 100%;
    }

    &__container {
      z-index: 2;
    }
    //background-image: url('/images/pages/Home/home-slider__slide3.webp');
    //background-size: cover;
    //background-position-x: 60%;
    //
    //@media (max-width: 768px) {
    //  background-position-x: 30%;
    //}
    //
    //@media (max-width: 576px) {
    //  background-position-x: 49%;
    //}

    .home-slider {
      &__slide-content-box {
        flex-direction: column;
        max-width: 60%;


        @media (max-width: 768px) {
          max-width: 90%;
        }
      }

      &__slide-description {
        margin-bottom: 100px;
        max-width: 50%;

        @media (max-width: 768px) {
          max-width: 100%;
        }
      }

      &__slide-list {
        @media (max-width: 576px) {
          flex-wrap: wrap;
        }
      }

      &__slide-list-item {
        @media (max-width: 576px) {
          margin-bottom: 40px;
          flex: 0 0 50%;
        }
      }

      &__slide-list-item-text {
        @media (max-width: 576px) {
          display: none;
        }
      }
    }
  }

  .slide4 {
    .home-slider {
      &__slide-image {
        @media (max-width: 768px) {
          margin-top: 200px;
          margin-right: 0;
          margin-left: -50px;
        }
      }

      &__slide-description {
        @media (max-width: 768px) {
          flex: 0 0 61%;
          max-width: 56%;
        }

        @media (max-width: 576px) {
          flex: 0 0 90%;
          max-width: 90%;
        }
      }
    }
  }

  .slide5 {
    background-image: url('/images/pages/Home/home-slider__slide5-bg.webp');
    background-size: cover;

    .home-slider {
      &__slide-content-box {
        justify-content: space-between;
        align-items: center;

        @media (max-width: 1200px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      &__slide-description {
        max-width: 35%;

        @media (max-width: 1200px) {
          max-width: 100%;
          margin-bottom: 40px;
        }
      }

      &__slide-list {
        max-width: 65%;
        flex-wrap: wrap;
        gap: 16px;

        @media (max-width: 1200px) {
          max-width: 100%;
          flex-wrap: nowrap;
          justify-content: flex-start;
          overflow-x: scroll;
        }
      }

      &__slide-list-item {
        flex: unset;
        width: 372px;
        padding: 16px;
        border-radius: 10px;
        background-color: rgba(255, 255, 255, 0.12);

        @media (max-width: 1200px) {
          min-width: 372px;
          width: 100%;
        }
      }

      &__slide-list-item-icon-box {
        margin-bottom: 8px;
        width: 48px;
        height: 48px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 6px;
        background-color: #292f47;
      }

      &__slide-list-item-icon-box.route {
        border-right: 1px solid $advantages-icon-crimson;
      }

      &__slide-list-item-icon-box.link {
        border-right: 1px solid $advantages-icon-orange;
      }

      &__slide-list-item-icon-box.location {
        border-right: 1px solid $icon-blue-hover;
      }

      &__slide-list-item-icon-box.chart {
        border-right: 1px solid $advantages-icon-purple;
      }

      &__slide-list-item svg {
        margin-bottom: 0;
      }
    }
  }
}
