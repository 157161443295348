.payment-protection {
  position: relative;
  top: 65px;
  margin-bottom: 65px;
  overflow-x: hidden;

  // SECTION: INTRO
  &__intro {
    background-image: url('/images/main/payment-protection2/1.png');
    background-size: cover;
    padding: 150px 0;

    @media (max-width: 768px) {
      background-image: url('/images/main/payment-protection2/4.png');
    }

    @media (max-width: 576px) {
      padding: 100px 0;
    }
  }

  &__intro-header {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1064px) {
      flex-direction: column;
      align-items: normal;
    }
  }

  &__intro-header-description-box {
    flex: 0 0 45%;

    @media (max-width: 1064px) {
      max-width: 702px;
    }

    @media (max-width: 576px) {
      align-items: center;
      display: flex;
      flex-direction: column;
    }
  }

  &__intro-header-image-box {
    flex: 0 0 45%;
    position: relative;

    @media (max-width: 1064px) {
      text-align: center;
      margin-top: 100px;
    }
  }

  &__intro-header-image {
    max-width: 578px;

    @media (max-width: 768px) {
      margin-left: -40px;
    }

    @media (max-width: 576px) {
      max-width: 390px;
    }
  }

  &__intro-header-title {
    @include page-heading-h1($white, true);
    //@include text(48px, 600, unset, none, $white);
    //margin-bottom: 24px;
    //line-height: 60px;

    //@media (max-width: 576px) {
    //  font-size: 24px;
    //  line-height: 34px;
    //}
  }

  &__intro-header-text {
    @include page-heading-h1-description($disabled-text, true);
    //@include text(16px, 500, unset, none, $disabled-text);
    width: 90%;
    //line-height: 26px;

    //@media (max-width: 576px) {
    //  font-size: 14px;
    //}
  }

  &__intro-header-text:last-child {
    margin-top: 20px;
  }

  &__intro-header-button {
    display: inline-block;
    text-align: center;
    //margin-top: 50px;
    padding: 12px;
    background-color: $white;
    width: 35%;
    border-radius: 6px;

    @include text(16px, 600, unset, none, $blue-dark);

    @media (max-width: 576px) {
      width: 100%;
      max-width: 343px;
    }
  }

  &__intro-plaque {
    margin-top: 92px;
    padding: 64px;
    display: flex;
    justify-content: space-between;
    background: rgba(255, 255, 255, 0.05);
    position: relative;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 16px;

    @media (max-width: 768px) {
      flex-direction: column;
    }

    @media (max-width: 576px) {
      padding: 64px 20px;
    }
  }

  &__intro-plaque-item {
    flex: 0 0 46%;
  }

  &__intro-plaque-item:last-child {
    @media (max-width: 768px) {
      margin-top: 40px;
    }
  }

  &__intro-plaque-image {
    margin-bottom: 14px;
  }

  &__intro-plaque-title {
    @include text(18px, 600, unset, none, $white);
    line-height: 28px;
    margin-bottom: 10px;
  }

  &__intro-plaque-text {
    @include text(14px, 500, unset, none, #9ea9b9ff);
    line-height: 22px;
  }

  &__intro-scheme {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 168px;

    @media (max-width: 576px) {
      margin-top: 100px;
    }
  }

  &__intro-scheme-title {
    @include text(36px, 600, unset, none, $white);
    line-height: 48px;
    text-align: center;

    @media (max-width: 576px) {
      line-height: 32px;
      font-size: 22px;
    }
  }

  &__intro-scheme-title > .word-part {
    position: relative;
    background: -webkit-linear-gradient(rgba(114, 174, 255, 1), rgba(135, 48, 255, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin: 0 12px;
  }

  &__intro-scheme-title > .word-part > .vector {
    position: absolute;
    bottom: -20px;
    left: 0;

    @media (max-width: 576px) {
      transform: scale(0.7);
      left: -40px;
    }
  }

  &__intro-scheme-list {
    margin-top: 80px;
  }

  &__intro-scheme-item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    max-width: 692px;
    margin-bottom: 40px;

    @media (max-width: 576px) {
      flex-direction: column;
    }
  }

  &__intro-scheme-item-image {
    flex: 0 0 20%;

    @media (max-width: 576px) {
      margin-bottom: 20px;
    }
  }

  &__intro-scheme-item-text {
    @include text(16px, 400, unset, none, #e0e4ecff);
    line-height: 26px;
    flex: 0 0 75%;
  }

  &__intro-scheme-item-text > .word-part {
    font-weight: 600;
  }

  &__intro-scheme-item-text .new-link {
    @include new-text(16px, 400, unset, none, underline, #e0e4ecff);
    transition: $transition;
    cursor: pointer;
  }

  &__intro-scheme-item-text .new-link:hover {
    @include new-text(16px, 400, unset, none, none, $white);
  }

  // SECTION: COVERAGE
  &__coverage {
    padding: 130px 0;
    background-color: $blue-dark;

    .container {
      display: flex;
      justify-content: space-between;

      @media (max-width: 768px) {
        flex-direction: column;
      }
    }
  }

  &__coverage-box {
    width: 45%;

    @media (max-width: 768px) {
      width: 100%;
    }
  }

  &__coverage-description-box {
    margin-bottom: 148px;

    @media (max-width: 768px) {
      margin-bottom: 70px;
    }
  }

  &__coverage-heading {
    margin-bottom: 24px;
    @include new-text(36px, 600, 48px, none, none, $white);

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__coverage-image-box {
    @media (max-width: 768px) {
      text-align: center;
      margin-bottom: 70px;
    }
  }

  &__coverage-image {
    @media (max-width: 425px) {
      width: 100%;
      height: 100%;
    }
  }

  &__coverage .payment-protection__list-item {
    @include new-text(16px, 500, 26px, none, none, $white);
  }

  // SECTION: CHECKLIST
  &__checklist {
    padding: 100px 0;
  }

  &__checklist > .container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 1064px) {
      flex-direction: column;
    }
  }

  &__checklist-description {
    flex: 0 0 30%;

    @media (max-width: 1064px) {
      margin-bottom: 40px;
    }
  }

  &__checklist-title {
    @include text(36px, 600, unset, none, $blue-dark);
    line-height: 48px;

    @media (max-width: 1064px) {
      font-size: 32px;
      line-height: 42px;
      display: inline-block;
    }

    @media (max-width: 576px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__checklist-subtitle {
    @include text(36px, 600, unset, none, $blue-dark);
    line-height: 48px;
    background: -webkit-linear-gradient(rgba(114, 174, 255, 1), rgba(135, 48, 255, 1));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 1064px) {
      font-size: 32px;
      line-height: 42px;
      display: inline-block;
      margin-left: 7px;
    }

    @media (max-width: 576px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__checklist-text {
    @include text(12px, 500, unset, none, #9ea9b9ff);
    line-height: 20px;
    margin-top: 30px;
    width: 90%;

    @media (max-width: 1064px) {
      max-width: 688px;
    }
  }

  &__checklist-box {
    flex: 0 0 68%;
    display: flex;
    justify-content: space-between;

    @media (max-width: 1064px) {
      flex-direction: column;
    }
  }

  &__list {
    flex: 0 0 48%;
  }

  &__list-item {
    @include text(16px, 500, unset, none, $blue-dark);
    line-height: 26px;
    position: relative;
    padding-left: 15px;
    margin-bottom: 16px;
  }

  &__list-item:after {
    content: '';
    height: 6px;
    width: 6px;
    border-radius: 50%;
    background: linear-gradient(59.74deg, #72aeff 0%, #8730ff 100%);
    position: absolute;
    top: 8px;
    left: 0;
  }

  // SECTION: MANAGEMENT
  &__management {
    padding: 100px 0;
    background: #f8f9fdff;
  }

  &__management > .container {
    max-width: 1300px;
  }

  &__management-content {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 1164px) {
      align-items: normal;
      flex-direction: column;
    }
  }

  &__management-image-box {
    flex: 0 0 45%;
    position: relative;

    @media (max-width: 1164px) {
      text-align: center;
      margin-bottom: 80px;
    }
  }

  &__management-gradient {
    position: absolute;
    z-index: 0;

    &.gradient-1 {
      top: -50%;
      left: -360px;

      @media (max-width: 576px) {
        top: -123%;
        left: -324px;
      }
    }

    &.gradient-2 {
      bottom: -69%;
      right: -27%;

      @media (max-width: 768px) {
        bottom: -100%;
        right: -10%;
      }

      @media (max-width: 576px) {
        bottom: -150%;
        right: -60%;
      }
    }
  }

  &__management-image {
    max-width: 614px;
    position: relative;
    z-index: 1;

    @media (max-width: 576px) {
      max-width: 305px;
    }
  }

  &__management-list-box {
    flex: 0 0 45%;
  }

  &__management-title {
    @include text(36px, 600, unset, none, $white);
    line-height: 48px;
    background: -webkit-linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;

    @media (max-width: 576px) {
      font-size: 22px;
      line-height: 32px;
      margin-bottom: 20px;
    }
  }

  &__management-subtitle {
    @include text(16px, 600, unset, none, $blue-dark);
    line-height: 28px;
    margin-bottom: 40px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  &__management-list {
  }

  &__management-item {
    position: relative;
    padding-left: 15px;
    @include text(16px, 500, unset, none, $blue-dark);
    line-height: 26px;
    margin-bottom: 10px;

    @media (max-width: 768px) {
      max-width: 520px;
    }
  }

  &__management-item:after {
    position: absolute;
    top: 8px;
    left: 0;
    height: 6px;
    width: 6px;
    content: '';
    border-radius: 50%;
    background: linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
  }

  // SECTION: TIMELINE
  &__timeline {
    padding: 100px 0;
    background: $blue-dark;
  }

  &__timeline-header-box {
    text-align: center;
    position: relative;
  }

  &__timeline-title {
    @include text(36px, 600, unset, none, $white);
    line-height: 48px;
    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 42px;
    }

    @media (max-width: 576px) {
      font-size: 22px;
      line-height: 32px;
    }
  }

  &__timeline-subtitle {
    @include text(16px, 400, unset, none, $white);
    line-height: 26px;

    @media (max-width: 576px) {
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__timeline-gradient {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__timeline-box {
    height: 470px;
    position: relative;
    min-width: 1440px;
    width: 100%;
    color: $white;
    margin: 84px 0;
  }

  &__timeline-box > .container {
    display: flex;
    height: 100%;
    min-width: 1440px;
    padding: 0 160px 0 100px;
    justify-content: space-between;
  }

  &__timeline-box:after {
    position: absolute;
    content: '';
    top: 50%;
    width: 100%;
    height: 6px;
    background: rgba(130, 82, 255, 0.47);
    transform: translateY(-50%);
  }

  &__timeline-container {
    position: relative;
    flex: 0 0 15%;
    height: 100%;
    display: flex;
    align-items: flex-end;

    .payment-protection__tooltip {
      margin-bottom: 340px;
    }
  }

  &__timeline-container.bottom {
    .payment-protection__tooltip {
      margin-bottom: 0;
      margin-top: 300px;

      &:after {
        bottom: unset;
        top: -10px;
        transform: rotate(180deg);
      }
    }
  }

  &__timeline-container.double {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;

    .payment-protection__tooltip.mb-230 {
      margin-bottom: 230px;
    }

    .payment-protection__tooltip.mb-0 {
      margin: 0 -30px 0 20px;

      &:after {
        bottom: unset;
        top: -10px;
        transform: rotate(180deg);
      }
    }
  }

  &__timeline-container:nth-last-child(-n + 3) {
    .payment-protection__timeline-legend {
      transform: translate(30%, 30%);
    }
  }

  &__timeline-container:nth-last-child(-n + 2) {
    flex: 0 0 25%;

    .payment-protection__timeline-legend {
      transform: translate(45%, 30%);
    }
  }

  &__timeline-container:last-child {
    flex: 0 0 28%;
  }

  &__timeline-time {
    position: absolute;
    bottom: calc(50% + 32px);
    right: 0;
    transform: translate(50%, -50%);

    &.first-circle {
      left: 0;
      right: unset;
      transform: translate(-50%, -50%);
    }
  }

  &__timeline-container:first-child:before {
    position: absolute;
    content: '';
    bottom: 50%;
    left: 0;
    transform: translate(-50%, 50%);
    border-radius: 50%;
    z-index: 1;
    width: 12px;
    height: 12px;
    border: 6px solid rgba(135, 48, 255, 1);
    background: $white;
  }

  &__timeline-container:after {
    position: absolute;
    content: '';
    bottom: 50%;
    right: 0;
    transform: translate(50%, 50%);
    border-radius: 50%;
    z-index: 1;
    width: 12px;
    height: 12px;
    border: 6px solid rgba(135, 48, 255, 1);
    background: $white;
  }

  &__timeline-container.md-circle:after {
    width: 16px;
    height: 16px;
    border-width: 8px;
  }

  &__timeline-container.lg-circle:after {
    width: 20px;
    height: 20px;
    border-width: 10px;
  }

  &__timeline-legend {
    position: absolute;
    bottom: 35%;
    right: 0;
    transform: translate(50%, -50%);
    min-width: 205px;
    text-align: center;
  }

  &__timeline-legend.first-circle {
    right: unset;
    left: 0;
    transform: translate(-50%, -50%);
  }

  &__timeline-legend.middle {
    left: 90%;
    right: unset;
    transform: translate(20%, 30%);
    line-height: 22px;
  }

  // TOOLTIP
  .tooltip__box {
    position: relative;
    display: inline;
    padding: 2px;

    &:hover {
      cursor: help;
    }
  }

  .tooltip__icon {
    margin-bottom: -3px;
    position: relative;
    z-index: 100;
  }

  &__tooltip {
    max-width: 425px;
    padding: 20px;
    border-radius: 10px;
    text-align: center;
    background: rgba(255, 255, 255, 0.2);
    position: relative;
    margin: 0 -20px;

    &.mb-20 {
      margin-bottom: 20px !important;
    }

    @include text(14px, 500, unset, none, $white);
    line-height: 22px;

    &:after {
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid rgba(255, 255, 255, 0.2);
      content: '';
      position: absolute;
      left: 50%;
      bottom: -10px;
      margin-left: -10px;
    }
  }

  &__scroll-box {
    overflow-x: scroll;
    scroll-behavior: smooth;
    transition: all 0.4s linear;

    @media (max-width: 1439px) {
      &:hover {
        cursor: grab;
      }

      &:active {
        cursor: grabbing;
      }
    }

    //@include media(max, 1439) {
    //    &:hover {
    //        cursor: grab;
    //    }
    //
    //    &:active {
    //        cursor: grabbing;
    //    }
    //}
  }

  &__scroll-box::-webkit-scrollbar {
    visibility: hidden;
  }

  &__disclaimer-box {
    text-align: center;
  }

  &__disclaimer {
    text-align: center;
    @include text(16px, 400, unset, none, $white);
    line-height: 26px;

    a {
      color: $blue-middle;
      text-decoration: underline;
    }
  }

  &__marker {
    background: transparent;
    background: -webkit-linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

// TOOLTIP
.tooltip__container-box {
  position: absolute;
  left: 2px;
  transform: translate(-21%, -130%);
  border-radius: 12px;
  padding-bottom: 1px;
  transition: opacity 0.2s linear;
  opacity: 0;

  &:after {
    content: '';
    top: 1px;
    left: 1px;
    right: 1px;
    border-radius: 6px;
    bottom: 22px;
    position: absolute;
    backdrop-filter: blur(3px);
  }
}

.tooltip__text {
  position: absolute;
  width: 250px;
  top: 11%;
  left: 8%;
  z-index: 2;
  text-align: center;

  @include text(14px, 500, unset, none, $white);
  line-height: 22px;

  @media (max-width: 576px) {
    top: 12%;
    left: 4%;
  }

  //@include media(max, 576) {
  //    top: 12%;
  //    left: 4%;
  //}
}

.tooltip__image {
  max-width: 300px;

  @media (max-width: 576px) {
    max-width: 270px;
  }

  //@include media(max, 576) {
  //    max-width: 270px;
  //}
}
