.freight-forwarder-landing {
    padding: 65px 0;
    position: relative;

    background: $gradient-dubai;

    // IMAGE BLOCK
    &__head-image-box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 500px;
        position: absolute;
        top: 240px;
        right: 150px;
        z-index: 1;

        @media (max-width: 1140px) {
          right: 120px;
        }

        @media (max-width: 1090px) {
          right: 60px;
        }

        @media (max-width: 1024px) {
            width: 600px;
            top: 210px;
            right: -90px;
        }

        @media (max-width: 1024px) {
            right: -190px;
        }

        @media (max-width: 768px) {
            right: -220px;
        }

        @media (max-width: 690px) {
            right: -300px;
        }

        @media (max-width: 575px) {
            max-width: 500px;
            width: 98%;
            top: 590px;
            right: 10px;
        }

        svg {
            position: absolute;
            top: 0;
            left: -250px;

            @media (max-width: 1140px) {
                left: -150px;
            }

            @media (max-width: 1140px) {
                left: -25px;
            }

            @media (max-width: 575px) {
                position: absolute;
                width: 20%;
                height: 20%;
                top: -70px;
                left: 59%;
                transform: rotate(45deg);
            }
        }
    }

    &__head-image {
        width: 100%;
        object-fit: contain;
    }

    &__head {
        padding: 245px 0;
        background-image: url('/images/pages/Resources/FreightDictionary/landings/head-bg.png');
        background-size: cover;
        overflow: hidden;

        @media (max-width: 768px) {
            padding: 245px 0 290px 0;
        }

        @media (max-width: 575px) {
            padding: 135px 0 575px 0;
        }
    }

    // HEAD: DESCRIPTION BLOCK
    &__head-description-box {
        max-width: 520px;
        width: 100%;
        position: relative;
        z-index: 2;
    }

    &__head-title {
        //@include text(48px, 600, none, none, $white);
        @include page-heading-h1($white, true);
        display: flex;
        flex-direction: column;
        //line-height: 60px;

        //@media (max-width: 768px) {
        //    font-size: 44px;
        //}
        //
        //@media (max-width: 575px) {
        //    font-size: 24px;
        //    line-height: 32px;
        //}
    }

    &__head-text {
        @include page-heading-h1-description($white, true);
        //@include text(16px, 400, none, none, $white);
        //margin-top: 32px;
        //line-height: 26px;
    }

    &__head-country-box {
        position: relative;
    }

    &__head-flag {
        position: absolute;
        bottom: 11px;
        margin-left: 18px;
        width: 39px;
        height: 26px;

        @media (max-width: 768px) {
            bottom: 8px;
        }

        @media (max-width: 575px) {
            bottom: 5px;
            width: 35px;
            height: 22px;
        }
    }

    &__head .flag-icon {
        min-width: unset;
    }

    // HEAD: OVERRIDES - LINK BLOCK
    &__head .new-header-hero__links-box {
        margin-top: 16px;

        @media (max-width: 768px) {
            margin-top: 40px;
        }

        @media (max-width: 575px) {
            flex-direction: column;
        }
    }

    &__head .new-header-hero__links-box {
        @media (max-width: 575px) {
            margin-top: 40px;
        }
    }

    &__head .new-header-hero__links-box .new-link-block.full {
        @media (max-width: 575px) {
            width: 100%;
            margin: unset;
        }
    }

    &__head .new-header-hero__links-box .new-link.arrow {
        @media (max-width: 575px) {
            margin-top: 24px;
        }
    }

    // INSTRUCTIONS
    &__instructions {
        //margin-top: 160px;
        //
        //@media (max-width: 575px) {
        //    margin-top: 80px;
        //}
    }

    &__instructions-section {
        display: flex;
        align-items: center;
        column-gap: 48px;

        @media (max-width: 1024px) {
            flex-direction: column;
        }
    }

    &__instructions-section.steps,
    &__instructions-section.become {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    &__instructions-description-box.start,
    &__instructions-description-box.become,
    &__instructions-description-box.steps {
        @media (max-width: 1024px) {
            margin-top: 60px;
        }

        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }

    &__instructions-description-box.steps {
        @media (max-width: 1024px) {
            order: 2;
        }
    }

    &__instructions-image-box {
        max-width: 540px;
        width: 100%;
        border-radius: 24px;

        @media (max-width: 1024px) {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    &__instructions-image-box img {
        border-radius: 24px;
    }

    &__instructions-title,
    &__instructions-list-title {
        @include text(36px, 600, none, none, $white);
        line-height: 48px;
        margin-top: 24px;

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 42px;
        }

        @media (max-width: 575px) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    &__instructions-list {
        list-style: auto;
    }

    &__instructions-list-item {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;
        margin-top: 16px;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 575px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__instructions-section.membership &__instructions-title {
        @media (max-width: 1024px) {
            margin-top: 60px;
        }

        @media (max-width: 768px) {
            margin-top: 40px;
        }
    }

    &__instructions-paragraphs {
        margin-top: 32px;

        @media (max-width: 768px) {
            margin-top: 24px;
        }
    }

    &__instructions-paragraphs .new-link-block.full {
        @include text(16px, 600, none, none, $dubai);
        padding: 12px 24px;
        max-width: 260px;
        width: 100%;
        background-color: $white;
        border: none;
        margin-top: 24px;

        @media (max-width: 575px) {
            max-width: unset;
            width: 100%;
        }
    }

    &__instructions-list {
        padding-left: 18px;
        list-style: decimal;
    }

    &__instructions-list-item,
    &__instructions-list-item a {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;

        @media (max-width: 575px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__instructions-list-item a {
        color: $white;
        text-decoration: underline;
    }

    &__instructions-paragraph,
    &__instructions-summary {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;
        margin-top: 16px;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 575px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__instructions-summary {
        margin-top: 34px;
    }

    &__instructions-paragraph a,
    &__instructions-summary a {
        @include text(16px, 400, none, none, $white);
        line-height: 26px;
        margin-top: 16px;
        text-decoration: underline;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 575px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__instructions-paragraph.become {
        margin-bottom: 16px;
    }

    // POSTS
    & .freight-dictionary__posts {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    & .freight-dictionary__posts-title-text,
    & .freight-dictionary__post-item-title {
        color: $white;
    }

    & .freight-dictionary__post-item {
        background-color: $storm;
    }

    & .freight-dictionary__post-item-date {
        color: $ice;
    }

    // ABOUT
    &__about {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    &__about-overlay {
        padding: 80px 48px;
        border-radius: 60px;
        background-color: $storm;
    }

    &__about-content {
        max-width: 1000px;
        width: 100%;
        margin: 0 auto;
    }

    &__about-title {
        @include text(36px, 600, none, none, $white);
        line-height: 48px;
        text-align: center;
        margin: 0 auto;

        @media (max-width: 768px) {
            font-size: 22px;
            line-height: 32px;
        }

        @media (max-width: 575px) {
            font-size: 18px;
            line-height: 28px;
        }
    }

    &__about-paragraph {
        @include text(16px, 500, none, none, $white);
        line-height: 26px;
        text-align: center;
        margin-top: 24px;

        @media (max-width: 768px) {
            font-size: 12px;
            line-height: 20px;
            margin-top: 16px;
        }

        @media (max-width: 575px) {
            font-size: 18px;
            line-height: 28px;
            margin-top: 8px;
        }
    }

    // LICENSE
    &__license {
        margin-top: 160px;

        @media (max-width: 575px) {
            margin-top: 80px;
        }
    }

    &__license-title {
        @include text(36px, 600, none, none, $white);
        line-height: 26px;
        text-align: center;

        @media (max-width: 768px) {
            font-size: 32px;
            line-height: 42px;
        }

        @media (max-width: 575px) {
            font-size: 22px;
            line-height: 32px;
        }
    }

    &__license-description-box {
        margin-top: 24px;

        @media (max-width: 768px) {
            margin-top: 16px;
        }
    }

    &__license-paragraph {
        @include text(16px, 600, none, none, $white);
        line-height: 26px;
        max-width: 1000px;
        width: 100%;
        text-align: center;
        margin: 0 auto;

        @media (max-width: 768px) {
            font-size: 14px;
            line-height: 22px;
        }

        @media (max-width: 575px) {
            font-size: 12px;
            line-height: 20px;
        }
    }

    &__license-button-box {
        margin-top: 24px;
        display: flex;
        justify-content: center;
        align-self: center;
    }

    &__license .new-link-block.full {
        @include text(16px, 600, none, none, $dubai);
        padding: 12px 24px;
        width: unset;
        background-color: $white;
        border: none;

        @media (max-width: 575px) {
            width: 100%;
        }
    }
}