$base-font-size: 14;

@function em($pixels, $context: $base-font-size) {
    @return #{$pixels/$context}em;
}

@mixin center {
    display: flex;
    align-items: center;
    justify-content: space-around;
}

@mixin absolut-center {
    position: absolute;

    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);
}

@mixin absolut-center-y {
    position: absolute;

    top: 50%;
    transform: translateY(-50%);
}

@mixin absolut-center-x {
    position: absolute;

    left: 50%;
    transform: translateX(-50%);
}


@mixin reset-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

@mixin reset-btn {
    border: none;
    outline: none !important;
    background: transparent;
}

@mixin opacity-hover($default, $hover, $duration: .3s) {
    opacity: $default;
    transition: opacity $duration 0s;

    &:hover {
        opacity: $hover;
    }
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/mixins";

// New styles:

@mixin media($minMax, $width) {
    @media only screen and (#{$minMax}-width: #{$width}px) { @content }
}

@mixin addArrowAnimation() {
    &__line {
        opacity: 1;
        transition: .15s ease;
    }
    &__tip {
        transform: translate3d(3px,0,0);
        transition: .15s ease;
    }
}

$breakpoints: (
        mobile: 375px,
        tablet: 768px,
        desktop: 1170px,
        desktop-m: 1240px,
        desktop-l: 1546px,
);

@mixin for-size($range) {
    $mobile: map-get($breakpoints, mobile);
    $tablet: map-get($breakpoints, tablet);
    $desktop: map-get($breakpoints, desktop);
    $desktop-m: map-get($breakpoints, desktop-m);
    $desktop-l: map-get($breakpoints, desktop-l);

    @if $range == mobile-only {
        @media screen and (max-width: #{$tablet - 1}) {
            @content;
        }
    } @else if $range == mobile {
        @media screen and (min-width: $mobile) {
            @content;
        }
    } @else if $range == tablet {
        @media screen and (min-width: $tablet) {
            @content;
        }
    } @else if $range == desktop {
        @media screen and (min-width: $desktop) {
            @content;
        }
    } @else if $range == desktop-m {
        @media screen and (min-width: $desktop-m) {
            @content;
        }
    } @else if $range == desktop-l {
        @media screen and (min-width: $desktop-l) {
            @content;
        }
    }
}
