//@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
//@import url('https://fonts.googleapis.com/css2?family=Lato:wght@400;700&display=swap');

button, a, p, div, input, h1, h2, h3, h4, h5, h6, span, li, ul {
    font-family: inherit !important;
}

//@font-face {
//    font-family: SF Pro Text;
//    src: url(/fonts/SFProDisplay-Bold.woff);
//    font-weight: bold;
//}
