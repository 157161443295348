@mixin h1 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 60px;

    @include media-breakpoint-down(lg) {
        font-size: 48px;
    }

    @include media-breakpoint-down(md) {
        font-size: 44px;
        line-height: 54px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 24px;
        line-height: 34px;
    }
}

@mixin h2 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 48px;

    @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: 42px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 22px;
        line-height: 32px;
    }
}

@mixin h2_v2 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 100%;
    margin-bottom: 32px;

    @include media-breakpoint-down(md) {
        font-size: 36px;
        margin-bottom: 24px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 32px;
    }
}

@mixin h2_v3 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 140%;

    @include media-breakpoint-down(md) {
        font-size: 32px;
        line-height: 130%;
    }

    @include media-breakpoint-down(xs) {
        font-size: 24px;
        line-height: 160%;
    }
}

@mixin h3 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;

    @include media-breakpoint-down(xs) {
        font-size: 18px;
        line-height: 28px;
    }
}

@mixin h3_v2 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 500;
    font-size: 19px;
    line-height: 26px;

    @include media-breakpoint-down(xs) {
        font-size: 17px;
        line-height: 24px;
    }
}

@mixin h3_v3 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 500;
    font-size: 21px;
    line-height: 28px;

    @include media-breakpoint-down(xs) {
        font-size: 17px;
        line-height: 24px;
    }
}

@mixin h4 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;

    @include media-breakpoint-down(xs) {
        font-size: 16px;
        line-height: 28px;
    }
}

@mixin h4_v2 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 160%;

    @include media-breakpoint-down(sm) {
        font-size: 28px;
    }

    @include media-breakpoint-down(xs) {
        font-size: 24px;
    }
}

@mixin body1 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    @include media-breakpoint-down(xs) {
        font-size: 14px;
        line-height: 22px;
    }
}

@mixin body2 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
}

@mixin body3 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
}

@mixin body4 {
    color: $dark-blue;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
}

@mixin body5 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
}

@mixin body6 {
    color: $dark-blue;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px
}

@mixin body7 {
    color: $dark-blue;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 26px;
}

@mixin body8 {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
}

@mixin body9 {
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
}

@mixin placeholder() {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    color: $light-grey;
}

.highlighted {
    color: $accent;
}

.link {
    color: $accent;
}

//Remove: text-decoration, text-transform
//Create mixins for links

@mixin text($size, $weight, $text-transform, $text-decoration, $color) {
    font-family: Montserrat, sans-serif;
    font-size: $size;
    font-weight: $weight;
    line-height: 100%;
    text-transform: $text-transform;
    text-decoration: $text-decoration;
    color: $color;
}
