.benefits {
    position: relative;
    padding: 160px 0 180px;

    &__title {
        font-weight: 600;
        font-size: 48px;
        line-height: 60px;

        color: $dark-blue1;
        text-align: center;
    }

    &__cards {
        position: relative;
        z-index: 1;

        margin-top: 80px;

        display: grid;
        grid-template-columns: repeat(3, 1fr);

        grid-gap: 30px;

        &-item {
            position: relative;
            z-index: 1;
            background: #FFFFFF;

            display: flex;
            flex-direction: column;

            padding: 16px 16px 8px;

            height: 480px;

            border-radius: 8px;
            @include shadow_1;
        }

        &-icon {
            margin-bottom: 16px;
            min-height: 48px;
        }

        &-title {
            font-size: 18px;
            line-height: 28px;
            font-weight: 600;

            margin-bottom: 16px;
        }

        &-text {
            color: $dark-grey;

            flex: 1;

            font-size: 16px;
            line-height: 26px;
        }

        &-link {
            max-width: 100px;
        }

        &:after {
            content: '';

            position: absolute;

            top: 50%;
            right: -30px;

            width: 231px;
            height: 231px;

            background: linear-gradient(59.74deg, #2DC8ED 0%, #548AF0 100%);
            opacity: 0.2;
            filter: blur(70px);
        }

        &:before {
            content: '';
            position: absolute;

            top: 35%;
            left: 50%;

            width: 231px;
            height: 566.52px;

            background: linear-gradient(59.74deg, #888BF4 0%, #5151C6 100%);
            opacity: 0.2;
            filter: blur(80px);
            transform: translateX(-50%) rotate(41.69deg);
        }
    }

    &:after {
        content: '';

        position: absolute;

        top: 70px;
        left: 20px;

        width: 231px;
        height: 231px;

        background: linear-gradient(59.74deg, #F573C3 0%, #C13DFF 100%);
        opacity: .2;
        filter: blur(80px);
    }

    &:before {
        content: '';
        position: absolute;

        top: 40px;
        left: 200px;

        width: 231px;
        height: 231px;

        background: linear-gradient(59.74deg, #2DC8ED 0%, #548AF0 100%);
        opacity: 0.2;
        filter: blur(80px);
    }
}

// 1200
@include media-breakpoint-down(lg) {
    .benefits {

        &__cards {

            &-item {
                height: 510px;
            }
        }
    }
}

// 992
@include media-breakpoint-down(md) {
    .benefits {
        padding: 190px 0 180px;

        &__cards {
            grid-template-columns: repeat(2, 1fr);
            grid-gap: 16px;

            &-item {
                height: 460px;
            }
        }
    }
}

// 768
@include media-breakpoint-down(sm) {
    .benefits {
        padding: 140px 0 80px;

        &__title {
            font-size: 44px;
            line-height: 54px;
            text-align: left;
        }

        &__cards {
            grid-template-columns: repeat(1, 1fr);
            grid-column-gap: 16px;
            grid-row-gap: 24px;

            &-item {
                height: 400px;
            }

            &-title {
                font-size: 16px;
                line-height: 28px;

                margin-bottom: 8px;
            }

            &-text {
                font-size: 14px;
                line-height: 22px;

                margin-bottom: 8px;
            }
        }

        &:after {
            top: -10px;
            left: auto;
            right: 100px;
        }

        &:before {
            top: 0;
            left: auto;
            right: -20px;
        }
    }
}

// 576
@include media-breakpoint-down(xs) {
    .benefits {

        &__title {
            text-align: center;
        }

        &__cards {
            grid-row-gap: 16px;

            &-item {
            }
        }

        &:after {
            top: -30px;
            left: -100px;
            right: auto;
        }
    }
}
