.SeaRatesERP {
  .erp-header-container {
    background-color: $dubai;
    padding-top: 184px;
    padding-bottom: 120px;

    background-image: url('/images/pages/Services/SeaRatesERP/bg_line.png'),
      url('/images/pages/Services/SeaRatesERP/section_1_eclipse.webp');
    background-repeat: no-repeat;
    background-position: center top, center -10px;

    @media (max-width: 969px) {
      padding-top: 142px;
      padding-bottom: 80px;
    }

    .erp-header {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;

      display: flex;

      @media (max-width: 969px) {
        flex-direction: column-reverse;
      }

      &__texts {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        margin-right: 24px;

        @media (max-width: 969px) {
          margin-top: 92px;
          align-items: center;
          margin-right: 0;
          text-align: center;
        }
        @media (max-width: 475px) {
          margin-top: 58px;
        }
        &-title {
          margin-top: -7px;
          margin-bottom: 22px;
          @include new-text(36px, 600, 60px, none, none, $white);

          @media (max-width: 969px) {
            margin-bottom: 28px;
            @include new-text(44px, 600, 54px, none, none, $white);
          }

          @media (max-width: 475px) {
            margin-bottom: 16px;
            @include new-text(22px, 600, 34px, none, none, $white);
          }
        }
        &-subtitle {
          max-width: 374px;
          margin-bottom: 48px;
          @include new-text(16px, 400, 26px, none, none, $white);

          @media (max-width: 969px) {
            margin-bottom: 38px;
          }

          @media (max-width: 475px) {
            margin-bottom: 48px;
          }
        }
        &-button {
          font-family: 'Montserrat', sans-serif;

          background-color: $blue-middle;
          cursor: pointer;
          border: none;
          padding: 11px 24px;
          border-radius: 6px;
          @include new-text(16px, 600, 26px, none, none, $white);

          @media (max-width: 475px) {
            width: 100%;
          }

          &:hover {
            opacity: 0.8;
          }
        }
      }

      &__image-wrapper {
        img {
          @media (max-width: 969px) {
            width: 100%;
          }
        }

        @media (max-width: 475px) {
          display: flex;
          justify-content: center;

          img {
            min-width: 405px;
          }
        }
      }
    }
  }

  .erp-what-container {
    background-color: $lapland;
    padding: 118px 0px 100px 0px;

    @media (max-width: 969px) {
      padding: 77px 0 52px 0;
    }

    @media (max-width: 475px) {
      padding: 30px 0 32px 0;
    }

    .erp-what-content {
      max-width: 1200px;
      padding: 0 15px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      @media (max-width: 969px) {
        flex-direction: column;
      }

      &__image-wrapper {
        margin-left: -4px;

        @media (max-width: 969px) {
          margin: 0 auto;

          img {
            margin-left: 11px;
          }
        }

        @media (max-width: 475px) {
          img {
            margin-left: 5px;
            width: 100%;
            transform: scale(1.03);
          }
        }
      }
      &__texts {
        max-width: 571px;
        margin-top: 48px;

        @media (max-width: 969px) {
          max-width: 636px;
          margin-top: 58px;
          margin-left: 6px;
        }

        @media (max-width: 475px) {
          margin-top: 51px;
          margin-left: 4px;
        }
      }
      &__title {
        margin-bottom: 31px;
        @include new-text(36px, 600, 36px, none, none, $dubai);

        @media (max-width: 969px) {
          @include new-text(32px, 600, 42px, none, none, $dubai);
          margin-bottom: 24px;
        }

        @media (max-width: 475px) {
          @include new-text(22px, 600, 32px, none, none, $dubai);
          margin-bottom: 16px;
        }
      }
      &__text {
        margin-bottom: 24px;
        @include new-text(16px, 400, 26px, none, none, $dubai);

        @media (max-width: 969px) {
          margin-bottom: 27px;
        }
      }
    }
  }

  .erp-offer-container {
    background-color: $dubai;
    padding-top: 120px;
    padding-bottom: 120px;

    background-image: url('/images/pages/Services/SeaRatesERP/bg_line.png'),
    url('/images/pages/Services/SeaRatesERP/section_1_eclipse.webp');
    background-repeat: no-repeat;
    background-position: center top, center -10px;

    @media (max-width: 969px) {
      padding: 80px 0px 80px 6px;
    }

    @media (max-width: 475px) {
      padding: 60px 0 44px 0;
    }

    .erp-offer-content {
      max-width: 1200px;
      padding: 0 15px;
      margin: 0 auto;
      display: flex;
      gap: 24px;

      @media (max-width: 969px) {
        flex-direction: column;
      }

      @media (max-width: 475px) {
        flex-direction: column-reverse;
        gap: 40px;
      }

      &__texts {
        display: flex;
        flex-direction: column;
        max-width: 508px;

        @media (max-width: 969px) {
          @include new-text(32px, 600, 42px, none, none, $white);
        }

        @media (max-width: 475px) {
          margin-left: 4px;
        }
      }
      &__title {
        margin-bottom: 24px;
        @include new-text(36px, 600, 48px, none, none, $white);

        @media (max-width: 969px) {
          @include new-text(32px, 600, 42px, none, none, $white);
        }

        @media (max-width: 475px) {
          @include new-text(22px, 600, 32px, none, none, $white);
        }
      }
      &__text {
        margin-bottom: 16px;
        @include new-text(16px, 400, 26px, none, none, $white);
      }

      &__image-wrapper {
        @media (max-width: 969px) {
          display: flex;
          justify-content: flex-end;
          img {
            width: 568px;
          }
        }

        @media (max-width: 475px) {
          img {
            width: 503px;
          }
        }
      }
    }
  }

  .erp-benefits-container {
    background-color: $lapland;
    padding: 120px 0;

    @media (max-width: 969px) {
      padding: 80px 0;
    }

    @media (max-width: 475px) {
      padding: 58px 0;
    }

    .erp-benefits-content {
      max-width: 1200px;
      padding: 0 15px;
      margin: 0 auto;
      &__texts {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
        gap: 34px;

        @media (max-width: 969px) {
          gap: 26px;
        }

        @media (max-width: 475px) {
          margin-bottom: 16px;
        }

        &-title {
          @include new-text(36px, 600, 48px, none, none, $dubai);

          @media (max-width: 969px) {
            @include new-text(32px, 600, 42px, none, none, $dubai);
          }

          @media (max-width: 475px) {
            @include new-text(22px, 600, 32px, none, none, $dubai);
          }
        }
        &-arrow {
          @media (max-width: 475px) {
            display: none;
          }
        }
      }
    }

    .erp-benefits__slide {
      @media (max-width: 969px) {
        width: fit-content;
      }
    }

    .erp-benefits__nav {
      display: none;

      @media (max-width: 969px) {
        display: flex;
        margin-left: auto;
        margin-right: 6px;
      }
    }

    .swiper-button-disabled {
      svg {
        path {
          fill: #c9cfdb !important;
        }
      }
    }

    .erp-benefits__item {
      max-width: 274px;
      border-radius: 16px;
      box-shadow: 0px 4px 15px 0px #3a70bf1a;
      background-color: $white;
      padding: 24px;
      padding-top: 26px;
      display: flex;
      flex-direction: column;

      @media (max-width: 969px) {
        width: 266px;
      }

      @media (max-width: 475px) {
        padding: 26px 24px;
        width: 258px;
      }

      &-icon-wrapper {
        margin-bottom: 14px;

        @media (max-width: 475px) {
          margin-bottom: 12px;
        }
      }
      &-title {
        @include new-text(18px, 600, 28px, none, none, $dubai);
        margin-bottom: 32px;

        @media (max-width: 475px) {
          @include new-text(16px, 600, 26px, none, none, $dubai);
          margin-bottom: 30px;
        }
      }
      &-text {
        @include new-text(14px, 400, 22px, none, none, $dubai);
      }
      &-button {
        @include new-text(14px, 400, 22px, underline, none, $blue-middle);
      }
    }
  }

  .erp-key-container {
    background-color: $dubai;
    padding-top: 120px;
    padding-bottom: 120px;

    @media (max-width: 969px) {
      padding: 80px 0;
    }

    @media (max-width: 475px) {
      padding: 60px 0;
    }

    background-image: url('/images/pages/Services/SeaRatesERP/bg_line.png'),
    url('/images/pages/Services/SeaRatesERP/section_1_eclipse.webp');
    background-repeat: no-repeat;
    background-position: center top, center -500px;
    .erp-key-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;
      &__texts {
        max-width: 690px;
        margin: 0 auto 80px auto;
        text-align: center;
        @media (max-width: 969px) {
          margin: 0 auto 65px auto;
        }
        @media (max-width: 475px) {
          text-align: left;
          margin: 0 auto 40px auto;
        }
        &-title {
          margin-bottom: 24px;
          @include new-text(36px, 600, 48px, none, none, $white);

          @media (max-width: 969px) {
            @include new-text(32px, 600, 42px, none, none, $white);
          }

          @media (max-width: 475px) {
            margin-bottom: 16px;
            @include new-text(22px, 600, 32px, none, none, $white);
          }
        }
        &-subtitle {
          @include new-text(16px, 400, 26px, none, none, $white);
        }
      }
      &__items {
        margin-left: 100px;
        display: grid;
        grid-template-columns: 234px 234px 234px 175px;
        grid-template-rows: repeat(3, 92px);
        grid-row-gap: 64px;
        justify-content: space-between;

        @media (max-width: 969px) {
          margin-left: 0px;
          grid-template-columns: 266px 266px 266px;
          grid-template-rows: repeat(4, 92px);
          grid-column-gap: 16px;
          justify-content: normal;

          div:nth-child(1) {
            order: 1;
          }

          div:nth-child(2) {
            order: 2;
          }

          div:nth-child(3) {
            order: 3;
          }

          div:nth-child(4) {
            order: 6;
          }

          div:nth-child(5) {
            order: 5;
          }

          div:nth-child(6) {
            order: 4;
          }

          div:nth-child(7) {
            order: 7;
          }

          div:nth-child(8) {
            order: 9;
          }

          div:nth-child(9) {
            order: 8;
          }

          div:nth-child(10) {
            order: 10;
          }

          div:nth-child(11) {
            order: 11;
          }

          div:nth-child(12) {
            order: 12;
          }
        }

        @media (max-width: 568px) {
          display: none;
        }

        &-item {
          &-image {
            margin-bottom: 16px;
          }
          &-title {
            @include new-text(18px, 600, 28px, none, none, $white);
          }
        }
      }
      &__slider {
        display: block;
        @media (min-width: 569px) {
          display: none;
        }
      }
      &__slide-item {
        display: flex;
        align-items: center;
        gap: 16px;
        margin-bottom: 24px;
        &-image {
          width: 40px;
          height: 40px;
          img {
            width: 100%;
          }
        }
        &-title {
          @include new-text(16px, 600, 26px, none, none, $white);
        }
      }

      &-nav {
        display: flex;
        margin-left: -14px;

        .swiper-button-disabled {
          svg {
            path {
              fill: #6e7e92 !important;
            }
          }
        }

        @media (min-width: 569px) {
          display: none;
        }
      }
    }
  }

  .erp-platform-container {
    background-color: $dubai;
    padding: 120px 0;

    @media (max-width: 969px) {
      padding: 80px 0;
    }

    @media (max-width: 475px) {
      padding: 55px 0;
    }

    .erp-platform-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 48px;
        @media (max-width: 969px) {
          margin-bottom: 40px;
        }
        @media (max-width: 475px) {
          margin-bottom: 24px;
        }
        &-title {
          @include new-text(36px, 600, 48px, none, none, $white);

          @media (max-width: 969px) {
            @include new-text(32px, 600, 42px, none, none, $white);
          }

          @media (max-width: 475px) {
            @include new-text(22px, 600, 32px, none, none, $white);
          }
        }
        &-nav {
          display: flex;
          .slider-platform-navigation {
            color: white;
          }

          .swiper-button-disabled {
            svg {
              path {
                fill: #6e7e92 !important;
              }
            }
          }
        }
      }

      .erp-platformSwiper {
        overflow: visible !important;

        &__item {
          width: auto;
        }
      }

      .erp-platform-item {
        max-width: 670px;

        @media (max-width: 969px) {
          max-width: 642px;
        }
        @media (max-width: 475px) {
          width: 100%;
        }
        &__image {
          width: fit-content;
          margin-bottom: 22px;
          padding: 16px 8px;
          background-color: $white;
          border-radius: 16px;

          @media (max-width: 969px) {
            margin-bottom: 16px;
            padding-bottom: 8px;
          }

          @media (max-width: 475px) {
            padding: 8px 4px 4px 4px;
            border-radius: 8px;
            margin-bottom: 12px;
          }

          img {
            width: 656px;
            height: 430px;

            @media (max-width: 969px) {
              width: 627px;
              height: 411px;
            }

            @media (max-width: 475px) {
              width: 100%;
              height: auto;
            }
          }
        }
        &__title {
          margin-bottom: 8px;
          @include new-text(20px, 600, 30px, none, none, $white);

          @media (max-width: 475px) {
            margin-bottom: 0px;
            @include new-text(18px, 600, 28px, none, none, $white);
          }
        }
        &__texts {
          height: 78px;
          overflow: hidden;
          @include new-text(16px, 400, 26px, none, none, $white);

          @media (max-width: 475px) {
            height: 52px;
          }
        }
        &__texts-open {
          height: auto;
        }
        &__btn {
          cursor: pointer;
          @include new-text(16px, 700, 26px, none, none, $white);
        }
      }
    }
  }

  .erp-form-container {
    background-color: $dubai;
    padding: 48px 0 160px 0;
    margin-top: -1px;

    @media (max-width: 969px) {
      padding-top: 74px;
    }

    @media (max-width: 475px) {
      padding-top: 68px;
    }
    .erp-form-content {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0 15px;

      .erp-form-section {
        background-image: url('/images/main/logisticsFinance/form/4.png');
        background-size: cover;
        padding: 120px 0 135px;

        @include mediaMixin(max, 576) {
          padding: 64px 0 80px;
        }

        &__content {
        }

        &__main-box {
          background: rgba($color: #ffffff, $alpha: 0.09);
          border: 1px solid rgba($color: #ffffff, $alpha: 0.23);
          border-radius: 16px;
          display: flex;
          justify-content: space-between;
          // align-items: center;

          @include mediaMixin(max, 998) {
            justify-content: center;
            min-height: 474px;
          }
        }

        &__image-box {
          flex: 0 0 27%;
          padding: 5px;

          @include mediaMixin(max, 998) {
            display: none;
          }
        }

        &__image {
          display: inline-block;
          border-radius: 10px, 200px, 10px, 10px;
          max-width: 423px;
        }

        &__form-content-box {
          flex: 0 0 57.5%;
          padding-top: 65px;

          @include mediaMixin(max, 998) {
            flex: 0 0 100%;
            padding: 80px 0 56px;
          }

          @include mediaMixin(max, 576) {
            padding: 60px 0 24px;
          }
        }

        &__form-content-box-header {
          margin-bottom: 43px;

          @include mediaMixin(max, 998) {
            margin-bottom: 41px;
          }

          @include mediaMixin(max, 475) {
            margin-bottom: 33px;
          }
        }

        &__form-title-h3 {
          @include h3;
          font-size: 24px;
          color: $color-white;
          font-weight: 600;
          position: relative;

          @include mediaMixin(max, 998) {
            padding-left: 64px;
          }

          @include mediaMixin(max, 475) {
            padding-left: 24px;
            font-size: 22px;
          }
        }

        &__form-title-image {
          position: absolute;
        }

        &__form-title-image.free {
          top: 0;
          left: 0;
          transform: translate(-125%, -80%);

          @include mediaMixin(max, 998) {
            top: -50px;
            left: 45px;
            transform: translate(0, 0);
          }

          @include mediaMixin(max, 475) {
            top: -43px;
            left: 18px;
          }
        }

        &__form-title-image.cloud {
          top: -40px;
          left: 215px;

          @include mediaMixin(max, 998) {
            left: 280px;
            top: -30px;
          }

          @include mediaMixin(max, 576) {
            left: 220px;
            top: -41px;
          }
        }

        &__disclamer-box {
          margin-top: 120px;
        }

        &__disclamer-title-h3 {
          @include h3;
          color: $color-white;
          font-size: 24px;
          margin-bottom: 24px;
        }

        &__disclamer-content {
          display: flex;
          justify-content: flex-start;

          @include mediaMixin(max, 576) {
            flex-wrap: wrap;
          }
        }

        &__text {
          font-size: 14px;
          color: $color-input;
          font-weight: 400;
          max-width: 400px;
          line-height: 22px;

          @include mediaMixin(max, 576) {
            flex: 0 0 100%;
            margin-bottom: 24px;
          }
        }

        &__text:first-child {
          margin-right: 68px;

          @include mediaMixin(max, 998) {
            margin-right: 30px;
          }
        }
      }

      .erp-demo-form {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-right: 64px;

        @include mediaMixin(max, 998) {
          padding: 0 64px;
          width: 100%;
        }

        @include mediaMixin(max, 475) {
          padding: 0 24px;
        }

        .select__phone {
          border-right: 1px solid $color-grey;
        }

        .select__panel--phone {
          border: 1px solid $color-grey;

          &:hover {
            border: 1px solid #08f;
            cursor: pointer;
          }
        }

        .input {
          flex: 0 0 48%;
          margin-bottom: 40px;

          @include mediaMixin(max, 998) {
            flex: 0 0 47.4%;
          }

          @include mediaMixin(max, 576) {
            flex: 0 0 100%;
            margin-bottom: 40px;
          }
        }

        input:-webkit-autofill,
        input:-webkit-autofill:hover,
        input:-webkit-autofill:focus,
        input:-webkit-autofill:active {
          transition: background-color 5000s ease-in-out 0s;
          -webkit-text-fill-color: white;
        }

        .input__body {
          background: transparent;
          border: 1px solid $color-grey;
          color: $color-white;
          transition: border 0.3s linear;

          &:hover {
            border: 1px solid #08f;
            cursor: pointer;
          }

          &:focus {
            border: 1px solid #08f;
          }
        }

        .input__label {
          font-weight: 700;
          color: $color-white;
          margin-bottom: 8px;
        }

        .select__panel--phone {
          background: transparent;
        }

        .select__panel-number--fill {
          color: $color-white;
        }

        .select__input {
          background: transparent;
          color: $color-white;
          padding: 0 16px;
        }

        &__button-wrapper {
          flex: 0 0 100%;
          display: flex;
          justify-content: flex-end;
          padding-bottom: 57px;

          @include mediaMixin(max, 998) {
            margin-top: -3px;
            padding-bottom: 0px;
          }

          @include mediaMixin(max, 576) {
            justify-content: center;
            margin-top: -8px;
          }
        }

        &__button {
          position: relative;
          padding: 14px 24px;
          font-size: 16px;
          color: $color-white;
          font-weight: 500;
          background: $color-blue;
          border-radius: 6px;
          outline: none;
          border: none;
          font-family: Montserrat, sans-serif;
          transition: all 0.2s linear;

          @include mediaMixin(max, 576) {
            width: 100%;
            padding: 18.25px 24px;
          }
        }

        &__button:hover {
          cursor: pointer;
          background: $color-btn-hover;
        }

        &__button:focus {
          background: $color-btn-press;
        }

        &__loader {
          left: 15px;
          top: 15%;
          position: absolute;
          height: 25px;
          width: 25px;
          margin-left: 10px;
          border-radius: 50%;
          border-top: 2px solid #fff;
          border-right: 2px solid transparent;
          animation: spinner 1s linear infinite;
        }
      }
    }
  }
}