section.code-of-ethic {
  margin-top: 70px;

  .container {
    .support__header {
      grid-template-columns: 1fr 1fr;

      @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
      }

      .support__header-info {
        max-width: 473px;
      }

      .support__header-images {
        max-width: 441px;
        height: auto;

        @include media-breakpoint-down(sm) {
          max-width: 100%;
        }
      }

      .support__header-suptitle {
        @include media-breakpoint-down(md) {
          margin-bottom: 45px;
        }
      }
    }

    .benefits-slider-wrapper {
      padding: 40px 0 170px 40px;

      @media (max-width: 1210px) {
        padding: 40px 0 170px 0;
      }

      @media (max-width: 640px) {
        padding: 40px 0 70px 0;
      }

      .code-of-ethic-slider {
        @media (max-width: 640px) {
          padding: 25px 0 60px 0;
        }

        .swiper-wrapper {
          display: grid;
          grid-template-columns: 1fr 1fr;
          grid-template-rows: repeat(5, 1fr);
          row-gap: 53px;
          column-gap: 40px;

          @include media-breakpoint-down(md) {
            row-gap: 24px;
            column-gap: 24px;
          }

          @media (max-width: 640px) {
            display: flex;
            grid-template-columns: none;
            grid-template-rows: none;
            row-gap: initial;
            column-gap: initial;
            align-items: center;
            justify-content: flex-start;
          }

          .swiper-slide {
            min-width: 100%;
            display: flex;
            align-content: center;
            justify-content: left;

            @media (max-width: 640px) {
              flex-grow: 1;
              flex-shrink: 1;
              padding: 10px;
            }

            .slide-wrapper {
              display: flex;
              flex-direction: row;
              align-items: flex-start;
              justify-content: flex-start;

              @include media-breakpoint-down(md) {
                flex-direction: column;
                padding: 20px;
              }

              @media (max-width: 640px) {
                box-shadow: 0 10px 30px rgba(58, 112, 191, 0.15);
                border-radius: 8px;
                min-width: 100%;
                min-height: 202px;
              }

              .slide_icon {
                display: block;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                width: 50px;
                height: 50px;
                min-width: 50px;
                min-height: 50px;
                margin-right: 24px;

                @include media-breakpoint-down(md) {
                  margin-right: 0;
                  margin-bottom: 12px;
                }

                &.icon1 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon1.svg');
                  box-shadow: 0px 10px 20px rgba(199, 48, 93, 0.2);
                }

                &.icon2 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon2.svg');
                  box-shadow: 0px 10px 20px rgba(0, 136, 255, 0.2);
                }

                &.icon3 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon3.svg');
                  box-shadow: 0px 10px 20px rgba(249, 98, 98, 0.2);
                }

                &.icon4 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon4.svg');
                  box-shadow: 0px 10px 20px rgba(135, 108, 191, 0.2);
                }

                &.icon5 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon5.svg');
                  box-shadow: 0px 10px 20px rgba(94, 71, 238, 0.2);
                }

                &.icon6 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon6.svg');
                  box-shadow: 0px 10px 20px rgba(119, 224, 212, 0.2);
                }

                &.icon7 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon7.svg');
                  box-shadow: 0px 10px 20px rgba(255, 185, 146, 0.2);
                }

                &.icon8 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon8.svg');
                  box-shadow: 0px 10px 20px rgba(240, 98, 18, 0.2);
                }

                &.icon9 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon9.svg');
                  box-shadow: 0px 10px 20px rgba(58, 177, 28, 0.2);
                }

                &.icon10 {
                  background-image: url('/images/main/codeOfEthic/slider-icons/icon10.svg');
                  box-shadow: 0px 10px 20px rgba(104, 44, 233, 0.2);
                }
              }

              p {
                font-style: normal;
                font-weight: normal;
                font-size: 14px;
                line-height: 22px;
                color: $dark-blue;
                max-width: 385px;
              }
            }
          }
        }

        .swiper-pagination {
          display: none;
          position: absolute;
          text-align: center;
          transform: translateZ(0);
          transition: opacity 0.3s;
          z-index: 10;
          bottom: 10px;
          width: 100%;

          @media (max-width: 640px) {
            background: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
          }

          .swiper-pagination-bullet {
            width: 8px;
            height: 8px;
            background: #c9cfdb;
            border-radius: 100%;
            margin: 0 4px;

            &.swiper-pagination-bullet-active {
              width: 12px;
              height: 12px;
              left: 162px;
              top: 1005px;
              background: $dark-blue;
            }
          }
        }
      }
    }
  }
}
