// Project colors:
$white: #ffffff;
$white-opacity: rgba(255, 255, 255, 0.1);
$white-transparent: rgba(255, 255, 255, 0.12);
$black: #000000;

$gray-disabled: #eff1f6;
$gray-light: #e0e4ec;
$gray-middle: #c9cfdb;
$gray-middle-02: #9ea9b9;
$gray-dark: #6e7e92;
$gray-dark-transparent: rgba(216, 224, 255, 0.3);
$gray-background: #f5f6ff;
$grey-lapland: #F8F9FD;

$blue-light-accent: #F2F9FF;
$blue-light: rgba(185, 199, 234, 0.05);
$blue-middle: #0088ff;
$blue-middle-hover: #50adff;
$blue-future: #006FF1;
$blue-dark: #000c37;
$blue-dark-02: #192145;
$blue-dark-03: #292F47;
$blue-dark-hover: #001357;

$violet-light: #6a71b6;
$violet-middle: #4f5590;
$violet-dark: rgba(106, 113, 182, 0.1);

// Home: icons' colors:
$icon-blue: linear-gradient(59.74deg, #2dc8ed 0%, #548af0 100%);
$icon-blue-webkit: -webkit-linear-gradient(59.74deg, #2dc8ed 0%, #548af0 100%);
$icon-blue-hover: #2dc8edff;
$icon-orange: linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
$icon-orange-webkit: -webkit-linear-gradient(59.74deg, #ffab1d 0%, #f8501c 100%);
$icon-orange-hover: #ffab1dff;
$icon-green: linear-gradient(59.74deg, #13c549 0%, #0dbbbb 100%);
$icon-green-webkit: -webkit-linear-gradient(59.74deg, #13c549 0%, #0dbbbb 100%);
$icon-green-hover: #13c549ff;
$icon-crimson: linear-gradient(59.74deg, #ff3b57 0%, #892168 100%);
$icon-crimson-webkit: -webkit-linear-gradient(59.74deg, #ff3b57 0%, #892168 100%);
$icon-crimson-hover: #ff3b57ff;
$icon-azure: linear-gradient(59.74deg, #7190bf 0%, #56f1d4 100%);
$icon-azure-hover: #7190bfff;
$icon-purple: linear-gradient(59.74deg, #72aeff 0%, #8730ff 100%);
$icon-purple-webkit: -webkit-linear-gradient(59.74deg, #72aeff 0%, #8730ff 100%);
$icon-purple-hover: #72aeffff;

// Benefits: icons' colors:
$digital-tools-icon-blue: linear-gradient(32.11deg, #0056FB -9.6%, #6F85FA 126.88%);
$digital-tools-icon-green: linear-gradient(134.41deg, #14C53B -6.31%, #11C88D 33.16%, #0CBBBB 94.74%);
$digital-tools-icon-gray: linear-gradient(225.82deg, #595D5D -1.44%, #252829 103.01%);;
$digital-tools-icon-border: #e89313;

// CargoesFlow icons' colors:
$advantages-icon-crimson: #be0b34;
$advantages-icon-purple: #5f3bff;
$advantages-icon-orange: #ff6c1d;

$dp-purple: linear-gradient(93.05deg, #5154B6 4.29%, #312A82 31.75%, #261D5E 72.43%, #1E1343 102.54%);

