// Remove all colors from here to "_colors.scss"
// Make "box-shadow" as a simple variable, not a mixin

$grid-breakpoints: (
    xs: 576px,
    sm: 768px,
    md: 992px,
    lg: 1200px,
    xl: 1400px,
    xxl: 1920px
);

$dark: #000C37;
$dark-blue: #000C37;
$dark-blue1: #15354E;

$dark-grey: #6E7E92;
$light-grey: #9EA9B9;
$light-blue: #F5F6FF;

$grey: #E0E4EC;
$grey1: #7C8C9E;
$grey2: #E9F0F7;

$white: #fff;
$white1: #F8F9FD;
$white2: #EFF1F6;
$white3: #F9FAFB;
$white4: #E5E8FF;

$black: #000;

$blue-extra-light: #F5F6FF;
$accent: #0088FF;
$purple: #5864FF;
$purple1: #888BF4;
$btn-hover-color: #1E2A55;
$btn-active-color: #00022D;

$blue: #0C1840;

$transition: all 0.3s ease;

@mixin shadow_1 {
    box-shadow: 0 10px 30px rgba(58, 112, 191, 0.15);
}
@mixin shadow_2 {
    box-shadow: 0 4px 15px rgba(58, 112, 191, 0.1);
}
@mixin shadow_3 {
    box-shadow: 0 4px 14px rgba(126, 164, 220, 0.08);
}

// Shadows:
$shadow-01: 0 10px 30px rgba(58, 112, 191, 0.15);
$shadow-02: 0 4px 15px rgba(58, 112, 191, 0.1);
$shadow-03: 0 4px 14px rgba(126, 164, 220, 0.08);
$shadow-04: 0px 4px 15px 0px rgba(58, 112, 191, 0.10);

@mixin media-height-down($name, $breakpoints: $grid-breakpoints) {
    $max: breakpoint-max($name, $breakpoints);
    @if $max {
        @media (max-height: $max) {
            @content;
        }
    } @else {
        @content;
    }
}
