section.support {
    padding: 120px 0 0 0;

    @include media-breakpoint-down(sm) {
        padding: 120px 0;
    }

    @include media-breakpoint-down(xs) {
        padding: 80px 0;
    }
}

.support {

    &__header {
        position: relative;

        display: grid;
        grid-template-columns: 1fr 2fr ;
        grid-gap: 40px;

        &-title {
            position: relative;
            z-index: 1;
            //@include h1;
            @include page-heading-h1($dubai, true);
            //margin-bottom: 24px;
        }

        &-suptitle {
            position: relative;
            z-index: 1;
            @include page-heading-h1-description($dubai, true);
            //@include body1;
            //color: $dark-grey;
            //margin-bottom: 24px;
        }

        &-btn {
            position: relative;
            z-index: 1;
            max-width: 222px;
        }

        &-info {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }

        &-images {

            img {
                width: 100%;
            }
        }

        &:after {
            content: '';

            position: absolute;

            width: 401.36px;
            height: 318.11px;

            top: 15%;
            left: -320px;

            background: #C82DFF;
            opacity: 0.1;
            filter: blur(75px);
            transform: matrix(-0.04, 1, 1, -0.03, 0, 0);
        }

        &:before {
            content: '';

            position: absolute;

            width: 168.97px;
            height: 207.29px;

            top: 50%;

            left: -250px;

            background: #0088FF;
            opacity: 0.3;
            filter: blur(75px);
            transform: matrix(-0.04, 1, 1, -0.03, 0, 0);
        }
    }

    &__list {
        display: grid;
        grid-template-columns: repeat(3, 1fr);

        grid-column-gap: 44px;
        grid-row-gap: 32px;
        margin-top: 80px;

        &-icon {
            margin-bottom: 16px;
            user-select: none;

            img {
                border-radius: 8px;
            }
        }

        &-title {
            @include h3;
            margin-bottom: 8px;
        }

        &-text {
            @include body2;
            color: $dark-grey;
        }
    }

    &__sup-header {
        margin-top: 60px;
        @include h3;
    }

    &__description {
        @include body2;

        margin-top: 80px;
        color: $dark-grey;

        max-width: 800px;
    }
}

// 992
@include media-breakpoint-down(md) {
    .support {

        &__header {
            grid-template-columns: 1fr 1.3fr;

            &-suptitle,
            &-title {
                margin-bottom: 16px;
            }
        }

        &__sup-header {
            margin-top: 100px;
        }

        &__list {
            grid-template-columns: repeat(2, 1fr);
            margin-top: 32px;
        }
    }
}

// 768
@include media-breakpoint-down(sm) {
    .support {

        &__header {
            grid-template-columns: 1fr;

            &-info {
                order: 1;
            }

            &-images {
                order: 0;
                padding: 0 15%;
            }
        }

        &__sup-header {
            margin-top: 80px;
        }

        &__description {
            margin-top: 48px;
        }
    }
}

// 576
@include media-breakpoint-down(xs) {
    .support {

        &__header {

            &-images {
                padding: 0;
            }
        }

        &__list {
            grid-row-gap: 40px;
            grid-template-columns: repeat(1, 1fr);

            margin-top: 48px;
        }
    }
}
